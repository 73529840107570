"use client";

import { useEffect } from "react";

import { useTranslations } from "next-intl";

import { Button } from "@sikt/sds-button";
import "@sikt/sds-button/dist/index.css";

import { useRouter } from "@/src/intl/navigation";
import style from "@/src/styles/error.module.css";

const ErrorComponent = ({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) => {
  useEffect(() => {
    console.error(error);
  }, [error]);

  const t = useTranslations("error");

  return (
    <div className={style.errorContainer}>
      <h1 className={style.errorName}>{error.name}</h1>
      <h2 className={style.errorDescription}>{error.message}</h2>
      <Button variant="strong" className={style.retryButton} onClick={reset}>
        {t("try-again")}
      </Button>
    </div>
  );
};

interface ErrorTryRefreshProps {
  /**
   * IMPORTANT: Cannot be of class Error, when used within a server component.
   * Classes cannot be passed from server components to client components.
   * */
  error: Error | string;
}

export const ErrorTryRefresh = ({ error }: ErrorTryRefreshProps) => {
  const router = useRouter();

  const reset = () => {
    router.refresh();
  };

  return (
    <ErrorComponent
      error={typeof error === "string" ? new Error(error) : error}
      reset={reset}
    />
  );
};

export default ErrorComponent;
